<template lang="pug">
AppLayout
  template(v-slot:header)
    v-spacer
    v-toolbar-title.cash-register-name.mx-2.align-center Настройки
    v-spacer
    v-icon mdi-cog

  t-list(title="General")
    t-list-item(title="Bottom Menu Icon")
      v-switch(color="teal")
    t-list-item(title="Bottom NavBar")
      v-switch(color="teal")
    t-list-item(title="Animation")
      v-switch(color="teal")
    t-list-item(title="Fullscreen Dialogs")
      v-switch(color="teal")
    t-list-item(title="Hide Home")
      v-switch(color="teal")
    t-list-item(title="Theme") Material Rally
    v-list-item
      v-btn(to="/ui", block, depressed, outlined, color="primary") Theme
    v-list-item
      v-btn(to="/ui/slots/check", block, depressed, outlined, color="primary") Check

  t-list(title="Catalog")
    t-list-item(title="Columns") 3
    t-list-item(title="Top NavBar Autohide")
      v-switch(color="teal")
    t-list-item(title="Show Remainings")
      v-switch(color="teal")
    t-list-item(title="Allow Plus Minus")
      v-switch(color="teal")
    t-list-item(title="Fix Editable")
      v-switch(color="teal")

  t-list(title="Tile")
    t-list-item(title="Show pic")
      v-switch(color="teal")
    t-list-item(title="Allow Edit")
      v-switch(color="teal")

  t-list(title="Cart")
    t-list-item(title="Animation")
      v-switch(color="teal")
    t-list-item(title="Float")
      v-switch(color="teal")
    t-list-item(title="Button Cart Icon")
      v-switch(color="teal")
    t-list-item(title="Always Edit")
      v-switch(color="teal")
    t-list-item(title="Accordion/Tab Mode")
      v-switch(color="teal")
    t-list-item(title="Delete Item by Swipe Right")
      v-switch(color="teal")
    t-list-item(
      title="Plus/Minus by Swipe Left",
      subtitle="Or show always in edit mode"
    )
      v-switch(color="teal")
</template>
<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import AppLayout from 'T@/AppLayout.vue'

export default {
  components: {
    Basket: () => import('T@/components/cart/Basket.vue'),
    AppLayout,
  },
  computed: {
    ...mapState({
    }),
  },
  data: () => ({
    left: 0,
    clicked: 0,
    right: 0,
  }),
  methods: {
    ...mapActions({
      clearCart: 'tjtheme/clearCart',
      addItem: 'tjtheme/addItem',
    }),
  },

}
</script>
<style lang="stylus"></style>
